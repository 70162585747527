import { AddToCartType, ProductCardType } from '@/constants'
import { labelPositionProductCard } from '@/constants/positions'
import { useAuth, useCart, useNotification } from '@/containers'
import { AddToCart, IconCheckedSuccess } from '@/styles/Icon'
import type { Product } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import ImageOptimize from '../ImageOptimize'
import { Loading } from '../Loading/Loading'
import Price from '../Price'
import PriceRelated from '../Price/PriceRelated'
import ProductLabel from '../ProductLabel'
import Countdown from './Countdown'
const StarRatings = dynamic(() => import('react-star-ratings'), {
  ssr: false,
})
// import PolicyCard from './PolicyCard'
export interface ProductProps {
  product: Product
  type?: string
  layout?: number
  className?: string
}

const ProductCard = ({ className, product, type, layout }: ProductProps) => {
  const percent = Math.ceil(100 - (product?.finalPrice / product?.price) * 100)
  const { systemConfig } = useNotification()
  const { fetchCart } = useAuth()
  const router = useRouter()
  const { addToCart, cartLoading, productLoading } = useCart()
  return (
    <div
      className={`productCard relative overflow-hidden rounded-xl border border-dark-100 ${
        className ? className : ''
      } ${type ? type : ''} ${layout ? 'l' + layout : ''} `}
      onClick={e => {
        e.stopPropagation()
        router.push(
          '/' + getTranslate(product?.slugs?.value) + product?.slugs?.postfix
        )
      }}
    >
      <div
        className={`itemProducts relative h-full items-center overflow-hidden bg-white ${
          layout ? 'l' + layout : ''
        } ${type == ProductCardType.TRENDING ? 'rounded-sm' : ''}`}
      >
        {percent && percent > 0 ? (
          <span className='absolute left-0 top-0 z-10 flex h-[30px] w-[54px] items-center justify-center rounded-br-xl rounded-tl-xl bg-primary-second text-base font-semibold text-white'>
            {'-' + percent + '%'}
          </span>
        ) : (
          ''
        )}
        <div
          className={`${
            type == ProductCardType.RELATED ? 'rounded' : 'p-[10px]'
          } image-thumb relative block w-full`}
        >
          {product?.labels &&
            product?.labels?.map((item, index) => {
              return (
                <>
                  {item?.image && (
                    <span
                      className={`${labelPositionProductCard.get(
                        item?.position
                      )} label hiddenScrollBar absolute flex h-auto w-full flex-nowrap gap-x-2 overflow-x-auto overflow-y-hidden`}
                      key={index}
                      style={{ zIndex: 50 + index }}
                    >
                      <ImageOptimize
                        src={item?.image}
                        alt={getTranslate(item?.name)}
                        width={200}
                        height={48}
                        loading='lazy'
                        className={`h-auto w-full`}
                      />
                    </span>
                  )}
                </>
              )
            })}
          <Link
            href={
              '/' +
              getTranslate(product?.slugs?.value) +
              product?.slugs?.postfix
            }
            className='relative block w-full overflow-hidden pt-[100%] focus:border-0 focus:outline-0'
          >
            <ImageOptimize
              className='productImage absolute left-0 top-0 aspect-square h-full w-full object-cover'
              src={
                product?.thumbnail
                  ? product?.thumbnail
                  : systemConfig?.imagePlaceholder ||
                    process.env.NEXT_PUBLIC_IMAGE_PLACEHOLDER ||
                    ''
              }
              alt={getTranslate(product?.name)}
              width={300}
              height={300}
              loading='lazy'
              thumbnail
            />
          </Link>
          {product?.flashSale && type != ProductCardType.TRENDING && (
            <Countdown
              productId={product?._id}
              endTime={product?.flashSale?.endTime}
              type={ProductCardType.PRODUCT_CARD}
              className={
                type == ProductCardType.RELATED ? 'bottom-2 justify-center' : ''
              }
            />
          )}
          {!product?.flashSale && (
            <div className='absolute bottom-0 left-2.5'>
              <ProductLabel label={product?.labelType} />
            </div>
          )}
        </div>
        <div
          className={`w-full pt-2 text-left ${
            type == ProductCardType.FLASH_SALE ? 'px-2' : ''
          } ${type == ProductCardType.RELATED ? '' : 'p-[10px]'} ${
            type == 'list' ? 'pb-3' : ''
          }
        ${
          type == ProductCardType.TRENDING
            ? 'rounded-b-sm border border-t-0 px-4 pb-3'
            : ''
        }`}
        >
          <Link
            href={
              '/' +
              getTranslate(product?.slugs?.value) +
              product?.slugs?.postfix
            }
            className={`${
              type == ProductCardType.RELATED ? '' : ''
            } mb-1 line-clamp-2 block h-[40px] overflow-hidden text-ellipsis text-titleProduct text-dark-900 focus:border-0 focus:outline-none`}
          >
            {getTranslate(product?.name)}
          </Link>
          {type === ProductCardType.RELATED ? (
            <PriceRelated
              type={ProductCardType.PRODUCT_CARD}
              classes=''
              link={
                '/' +
                getTranslate(product?.slugs?.value) +
                product?.slugs?.postfix
              }
              originPrice={product?.price}
              finalPrice={product?.finalPrice}
            />
          ) : (
            <Price
              type={ProductCardType.PRODUCT_CARD}
              classes=''
              link={
                '/' +
                getTranslate(product?.slugs?.value) +
                product?.slugs?.postfix
              }
              originPrice={product?.price}
              finalPrice={product?.finalPrice}
            />
          )}
          {/* <div
            className={`h-[30px] pt-1.5 ${
              !product?.preOrder && type === 'list' ? 'hidden' : ''
            }`}
          >
            {product?.preOrder && (
              <Link
                href={
                  '/' +
                  getTranslate(product.slugs.value) +
                  product.slugs.postfix
                }
              >
                <PreOrderCard
                  price={product?.preOrder.salePrice}
                  type={ProductCardType.PRODUCT_CARD}
                />
              </Link>
            )}
          </div> */}
          {/* <div
            className={`h-[28px] pt-0.5 ${
              !product?.flashSale && type === 'list' ? 'hidden' : ''
            }`}
          >
            {product?.flashSale && (
              <ProgressBar
                fsType={product.type}
                flashSale={product?.flashSale}
                type={ProductCardType.PRODUCT_CARD}
              />
            )}
          </div> */}
          <div className='flex items-center gap-1 leading-4'>
            <StarRatings
              starRatedColor='#FFCD4B'
              starHoverColor='#FFCD4B'
              numberOfStars={5}
              rating={5}
              name='point'
              starDimension='11px'
              starSpacing='0px'
              svgIconPath='M5.0876 0.0078125L6.60003 3.23362L9.98193 3.7509L7.53477 6.26184L8.11246 9.80735L5.0876 8.13339L2.06275 9.80735L2.64044 6.26184L0.193279 3.7509L3.57518 3.23362L5.0876 0.0078125Z'
              svgIconViewBox='0 0 10 10'
            />
            <span className='mt-[4px] text-xs italic leading-4 text-gray-500'>
              {/* ({parseFloat(product?.rate?.toFixed(2)) || '0.0'}) */}
              (5.0)
            </span>
          </div>
        </div>

        {/* {!product?.flashSale && type !== ProductCardType.FLASH_SALE && (
          <PolicyCard policy={product?.properties} />
        )} */}
        <button
          className='add2Cart absolute bottom-0 right-0 z-30 flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-br-xl rounded-tl-xl bg-primary-base'
          disabled={cartLoading}
          onClick={async e => {
            e.stopPropagation()
            addToCart(product, AddToCartType.ADD).then(() => {
              fetchCart()
              toast.success('Sản phẩm đã được thêm vào giỏ hàng', {
                position: 'bottom-right',
                icon: <IconCheckedSuccess />,
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
              })
            })
          }}
        >
          {cartLoading && productLoading == product._id ? (
            <Loading className='!mr-0' />
          ) : (
            <AddToCart />
          )}
        </button>
      </div>
    </div>
  )
}

export default ProductCard
